import { autoinject, bindable, containerless, observable } from 'aurelia-framework';
import { Api } from 'utils/api';
import AuthService from 'authService';

@containerless()
@autoinject
export class EyImg {

  @bindable url: string;
  @bindable class: string = "img-thumbnail p-1 mx-height-40";

  @observable src: string;

  constructor(private api: Api, private auth: AuthService) {
  }

  attached() {
  }

  urlChanged() {
    this.src = this.url;
    if (this.url.includes("?")) {
      this.src += "&";
    } else {
      this.src += "?";
    }
    this.src += "s=" + this.auth.authData.session;
  }
}
