import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Documento, TipoDocumento, PlantillaTpDoc } from 'entities/documento';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { DialogController } from 'aurelia-dialog';

@containerless()
@autoinject
export class EyDocumentoInfo {

  @observable tpDocumento: TipoDocumento;
  title: string;

  constructor(private api: Api, private ea: EventAggregator,
    private auth: AuthService, private controller: DialogController) {
  }

  activate(model) {
    this.tpDocumento = model.tpDoc;
    this.title = this.tpDocumento.nombre;
  }

  attached() {
  }

  cancel() {
    this.controller.cancel(false);
  }

  downloadPlantilla(p: PlantillaTpDoc) {
    console.log("Download plantilla", p);
  }
}
