import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Documento, TipoDocumento, SeleccionOrigenDocumento, Obligatoriedad } from 'entities/documento';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { EyDocumentoUpload } from './ey-documento-upload';
import { EyDocumentoInfo } from './ey-documento-info';
import { EyDocumentoVisor } from './ey-documento-visor';


export enum FilterType {
  NO_FILTER = 0,
  POR_SUBIR = 1,
  CADUCADO = 2,
  VALIDO = 3,
  PENDIENTE = 4,
  OPCIONAL_SIN_SUBIR = 5,
}

@containerless()
@autoinject
export class EyDocumentos {
  @bindable id: string = "";
  @bindable documentos: Documento[] = [];
  @bindable bloqueado: boolean = false;
  @bindable showFilter: boolean = true;
  @bindable filterType: FilterType = FilterType.POR_SUBIR;
  @bindable filterBySourceType: boolean = false;
  @observable selectedSourceType: SeleccionOrigenDocumento = SeleccionOrigenDocumento.TODOS;
  @observable selectedObligatorio: Obligatoriedad = Obligatoriedad.TODOS;

  parent: any;
  table: any;

  filterSub: Subscription;
  filterRemoveSub: Subscription;

  constructor(private api: Api, private ea: EventAggregator,
    private auth: AuthService) {
  }

  attached() {
    this.filterSub = this.ea.subscribe("doFilter", (data) => this.setFilter(data.filter));
    this.filterRemoveSub = this.ea.subscribe("removeFilter", () => this.removeFilter());
  }

  detached() {
    this.filterSub.dispose();
    this.filterRemoveSub.dispose();
  }

  documentosChanged() {
    console.log("EyDocumentos documentosChanged", this.documentos.length);
  }

  // updateTable() {
  // console.log("EyDocumentos: updateTable");
  // if (this.table) {
  //   this.table.destroy();
  // }

  // this.api.loadDataTable("documentos", { "order": [[0, "desc"]] })
  //   .then(table => {
  //     this.table = table;
  //   });
  // }

  updateDocumentos() {
    if (this.parent) {
      this.parent.updateDocumentos();
    }
  }

  setFilter(ft: FilterType) {
    console.log("setFilter", ft);
    if (this.filterType != ft) {
      this.filterType = ft;
      console.log("EyDocumentos: setFilter", this.filterType);
      // this.updateTable();
    }
  }

  removeFilter() {
    console.log("EyDocumentos: removeFilter");
    this.setFilter(FilterType.NO_FILTER);
  }

  isVisible(doc: Documento): boolean {
    // console.log("isVisible", this.filterType, doc);

    switch (this.filterType) {
      case FilterType.NO_FILTER:
        return true;
      case FilterType.POR_SUBIR:
        return doc.subido == 0;
      case FilterType.CADUCADO:
        return doc.subido == 1 && doc.vigente == 0;
      case FilterType.VALIDO:
        return doc.valido == 1;
      case FilterType.PENDIENTE:
        return doc.valido == 3;
      case FilterType.OPCIONAL_SIN_SUBIR:
        return doc.subido == 0 && doc.obligatorio == 0;
    }
    return false;
  }

  // downloadDoc(doc: Documento) {

  // }

  showExtraInfo(doc: Documento) {
    this.api.getOne(TipoDocumento, 'tipo_documentos', doc.tipo)
      .then(tpDoc => {
        if (tpDoc) {
          // console.log("showExtraInfo:", tpDoc.texto);

          // this.api.alert(tpDoc.nombre, tpDoc.texto, true);

          this.api.showEditorDialog({ viewModel: EyDocumentoInfo, model: { tpDoc: tpDoc } });
        }
      });
  }

  editDoc(doc: Documento) {
    this.api.showEditorDialog({ viewModel: EyDocumentoUpload, model: { doc: doc } })
      .then((response) => {
        if (response && !response.wasCancelled) {
          // this.api.showSnackbar('Información enviada correctamente', "");
          this.updateDocumentos();
        }
      });
  }

  validaDoc(doc: Documento) {
    this.api.confirm(this.api.tr("doc_validar"), this.api.tr("doc_validar_wn") + " <<" + doc.tipoNombre + ">> " + this.api.tr("deacuerdo"))
      .then(b => {
        if (!b)
          return;

        this.api.getPath('documentos/' + doc.id + '/valida')
          .then((v) => {
            console.log("valor:", v);
            if (v && v.data && !v.data.error) {
              this.updateDocumentos();
            }
          });
      })
  }

  regenera(doc: Documento) {

    this.api.confirm(this.api.tr("doc_regenera"), this.api.tr("doc_regenera_wn") + " <<" + doc.tipoNombre + ">> " + this.api.tr("doc_regenera_wn2"))
      .then(b => {
        if (!b)
          return;
        

        this.api.getPath('documentos/' + doc.id + '/regenera')
          .then((v) => {
            console.log("valor:", v);
            if (v && v.data && !v.data.error) {
              this.api.alert(this.api.tr("doc_regenera"), this.api.tr("doc_atencion") + " <<" + doc.tipoNombre + ">> " + this.api.tr("doc_atencion2"));
              this.updateDocumentos();
            }
          });
      })

  }

  showPDF(doc: Documento) {
    this.api.showEditorDialog({ viewModel: EyDocumentoVisor, model: { doc: doc } });
  }
}
