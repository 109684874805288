import { GetAPI } from 'utils/api';
import { autoinject, bindable, bindingMode, containerless, observable, TaskQueue } from 'aurelia-framework';
// import { I18N } from 'aurelia-i18n';

import * as $ from 'jquery';
import moment from 'moment';
import 'tempusdominus-bootstrap-4';
import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';

let minDate = moment('1950-01-01');
let maxDate = moment('2050-01-01');
let momentFormat = 'DD/MM/YYYY HH:mm:ss';
let momentOnlyDateFormat = 'DD/MM/YYYY';
let momentOnlyHourFormat = 'HH:mm:ss';
let serverFormat = 'DD-MM-YYYY HH:mm:ss';
let serverOnlyDateFormat = 'DD-MM-YYYY';
let serverOnlyHourFormat = 'HH:mm:ss';

@containerless()
@autoinject
export class EyFormInput {

  @bindable({ defaultBindingMode: bindingMode.twoWay }) value;
  // @bindable value; 

  //@bindable({ defaultBindingMode: bindingMode.twoWay }) checked: boolean; 

  // HTML element to use: input, select, field
  @bindable element = 'input';

  // HTML type of the element; text, phone, email, multiple (select)
  @bindable type = '';

  @bindable name = '';

  @bindable label = '';

  @bindable classes = '';

  @bindable placeholder = '';

  // Input extra information / help
  @bindable help = '';

  @bindable readonly = false;

  @bindable disabled = false;

  @bindable autofocus;

  @bindable required;

  @bindable max = undefined;

  @bindable min = undefined;

  @bindable step = undefined;

  @bindable multiline = false;
  @bindable multilineRows = 8;
  @bindable multilineCols = 40;

  // Select
  @bindable multiple = false;

  @bindable options = [];

  @bindable displayProperty = 'name';
  @bindable valueProperty = 'value';

  @bindable field = "";

  @bindable percentage: boolean; // For sliders

  @bindable accept: string;
  @observable warning: string;

  private fileInput: any;
  private fileLabel: any;

  private inputElement: Element;
  private dtElement: Element; // date time 

  @bindable small: boolean = false;


  @bindable({ defaultBindingMode: bindingMode.twoWay }) date: string;
  @bindable onlydate: boolean;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) hour: string;
  @bindable onlyhour: boolean;

  momentFormatUsed: string;

  constructor(private readonly el: Element, private readonly taskQueue: TaskQueue) {

  }

  attached() {

    if (this.element == 'select') {
      $(this.inputElement).selectpicker({
        iconBase: 'font-awesome',
        noneSelectedText: 'Nada seleccionado',
      });

      setTimeout(() => {
        $(this.inputElement).selectpicker('refresh');
      }, 200);

      // console.log("attached", this.displayProperty, this.valueProperty, this.options);

    } else if (this.fileInput) {

      $(this.fileInput).on('change', () => {
        //get the file name
        if (this.value.length == 1) {
          var fileName = this.value[0].name;
          //replace the "Choose a file" label
          $(this.fileLabel).html(fileName);
        }
      });
    } else if (this.type == "datetime") {
      this.momentFormatUsed = momentFormat;
      let dt = undefined;
      if (this.onlydate) {
        this.momentFormatUsed = momentOnlyDateFormat;
        if (this.date && this.date != "") {
          dt = moment(this.date, serverOnlyDateFormat);
        } else {
          // Si no hay fecha, dejar campo vacío
          // dt = moment();
        }
      } else if (this.onlyhour) {
        // TODO
      } else {
        if (this.date && this.date != "") {
          dt = moment(this.date + " " + this.hour, serverFormat);
        } else {
          // Si no hay fecha, dejar campo vacío
          // dt = moment();
        }
      }

      // actualiza campos
      if (dt) {
        this.date = dt.format('DD-MM-YYYY');
        this.hour = dt.format('HH:mm:ss');
      }

      $(this.dtElement).datetimepicker({
        format: this.momentFormatUsed,
        locale: 'es',
        defaultDate: dt,
        minDate: minDate,
        maxDate: maxDate,
      });

      $(this.dtElement).on('change.datetimepicker', (e) => {
        // console.log("Changed", e);
        if (e.date) {
          this.value = e.date.format(this.momentFormatUsed);
        }
      });

    }

    if (this.type == "cron") {
      this.help = "Check from format here http://www.cronmaker.com/";
    }

    // if (this.type == "password") {

    // }
  }

  detached() {
    if (this.element == 'select') {
      // console.log("Destroy select")
      $(this.inputElement).selectpicker('destroy');
    } else if (this.type == "datetime") {
      $(this.dtElement).datetimepicker('destroy');
    }
  }

  checkCapsLock(event: KeyboardEvent) {
    if (!event) {
      return true;
    }

    if (this.type != "password") {
      return true;
    }

    // true when you press the keyboard CapsLock key
    var caps = event.getModifierState && event.getModifierState('CapsLock');
    if (caps) {
      this.warning = "Bloq Mayús activado";
    } else {
      this.warning = "";
    }
    //console.log(caps); 

    return true;
  }

  compOptions = [];
  updateOptions() {
    // console.log("updateOptions", this.displayProperty, this.valueProperty, this.options);

    this.compOptions = [];

    this.options.forEach(opt => {

      let member = opt[this.displayProperty];
      let text = "";
      if (typeof member === 'function') {
        text = opt[this.displayProperty]();
      } else if (typeof member === 'undefined') {
        text = opt;
      } else {
        text = member;
      }


      // console.log("updateOptions", text, opt);
      // if (!text) {
      //     text = opt;
      // }

      if (typeof text === 'string') {
        // text = this.i18n.tr(text);
      }

      let value = opt;
      if (this.valueProperty) {
        value = opt[this.valueProperty];
        if (value == undefined) {
          value = opt;
        }
      }

      // console.log("updateOptions", opt, text, value);
      this.compOptions.push({ name: text, value: value })
    });

    this.compOptions = GetAPI().sortArrayNatural(this.compOptions, "name");
    
    if (this.element == 'select') {
      setTimeout(() => {
        console.log("Updating select")
        $(this.inputElement).selectpicker('refresh');
      }, 300);
    }
  }

  displayPropertyChanged() {
    if (this.element != 'select')
      return;

    // console.log("displayPropertyChanged", this.label, this.displayProperty);
    this.updateOptions();
  }

  valuePropertyChanged() {
    if (this.element != 'select')
      return;

    // console.log("valuePropertyChanged", this.label, this.valueProperty);
    this.updateOptions();
  }

  // disabledChanged() {
  //     console.log("disabledChanged", this.disabled);
  // }

  optionsChanged() {
    // console.log("optionsChanged", this.label, this.options);

    if (this.element != 'select')
      return;

    // console.log("optionsChanged", this.label, this.options);
    this.updateOptions();
  }

  valueChanged(vnew, vold) {
    // console.log("valueChanged", this.label, this.value, vnew, vold, this.value, this.isNumber(this.value), !isNaN(this.value), typeof this.value);

    // if (vnew != vold && this.type == "number" && typeof this.value == "string") {
    //     this.value = Number(this.value);
    // }

    if (this.type == "datetime") {
      console.log("datetime:", this.field, this.value);

      // let dt = $(this.dtElement).datetimepicker('viewDate');
      if (this.value == "") {
        this.date = "";
        this.hour = "";
      } else {
        let dt = moment(this.value, this.momentFormatUsed);
        if (!dt.isValid() || dt < minDate || dt > maxDate) {

          let api = GetAPI();
          this.warning = api.tr("fecha_no_valida");
          return;
        }

        this.warning = "";
        this.date = dt.format('DD-MM-YYYY');
        this.hour = dt.format('HH:mm:ss');
      }


      console.log("VALUE CHANGED", this.date, this.hour);
    } else if (this.type == "number") {

      // console.log("VALOR :", vnew, "min", this.min, "max", this.max);

      // if (this.min != undefined) {
      //   if (Number(vnew) < Number(this.min)) {
      //     vnew = this.min;
      //   }
      // }

      // if (this.max != undefined) {
      //   if (Number(vnew) > Number(this.max)) {
      //     vnew = this.max;
      //   }
      // }

      // this.value = vnew;

      console.log("VALOR ACABA:", vnew, "vold", vold, "min", this.min, "max", this.max, "value", this.value);
      if (vnew == vold) {
        return;
      }
    }

    const evt = new CustomEvent("change", {
      bubbles: true,
      detail: vnew
    });

    this.taskQueue.queueMicroTask(() => {
      this.el.dispatchEvent(evt);
    });

  }

  isNumber(n): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  fieldChanged() {
    if (this.label == "") {
      this.label = this.field;
    }
    // this.label = this.i18n.tr(this.field);
    // let helpKey = this.field + "_help";
    // let help = this.i18n.tr(helpKey);
    // if (help != helpKey)
    //   this.help = help;
  }

  // valueChanged(vnew: any, vold: any) {
  //     console.log("valueChanged", this.label, this.valueProperty, this.value, vnew, vold);
  // }

  // selectValueChanged(){
  //     console.log("selectValueChanged", this.label, this.valueProperty, this.value);
  // }
  // valueChanged(vnew: any, vold: any) {
  //     if (this.type == "number") {
  //         if (this.max && vnew > this.max) {
  //             this.value = this.max;
  //         } else if (this.min && vnew < this.min) {
  //             this.value = this.min;
  //         }
  //     }
  // }

  fileInputChanged() {

  }
}
