import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Documento } from 'entities/documento';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { DialogController } from 'aurelia-dialog';
import environment from 'environment';

@containerless()
@autoinject
export class EyDocumentoVisor {

  @observable doc: Documento;
  @observable docURL: string;
  title: string;

  constructor(private api: Api, private ea: EventAggregator,
    private auth: AuthService, private controller: DialogController) {
  }

  activate(model) {
    this.doc = model.doc;
    this.title = this.doc.nombre;

    let rand = Math.floor(Math.random() * 1000000);
    let path = "/documentos_download/" + this.doc.id + "?session=" + this.auth.authData.session + "&embed=1&cache="+rand;
    this.docURL = window.location.origin + path;
    if (environment.debug) {
      // docURL = "http://localhost/"+path;
      this.docURL = "http://www.ite.educacion.es/formacion/materiales/107/cd/html/pdf/html11.pdf";
    }

  }

  attached() {
  }

  cancel() {
    this.controller.cancel(false);
  }
}
