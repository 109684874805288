import { autoinject, bindable, containerless } from 'aurelia-framework';
import { EstadoDoc } from 'entities/documento';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FilterType } from './ey-documentos';

@containerless
@autoinject
export class EyEstadoDoc {
  @bindable owner: any;
  @bindable estado: EstadoDoc;

  constructor(private ea: EventAggregator) {
  }

  // attached() {
  //   $('[data-toggle="tooltip"]').tooltip();
  // }

  filter(type: number) {
    if (!this.owner) {
      return;
    }
    var ft = FilterType.POR_SUBIR;
    switch (type) {
      case 0:
        ft = FilterType.POR_SUBIR;
        break;
      case 1:
        ft = FilterType.PENDIENTE;
        break;
      case 2:
        ft = FilterType.VALIDO;
        break;
      case 5:
        ft = FilterType.OPCIONAL_SIN_SUBIR;
        break;
    }

    console.log("filter", ft);
    this.ea.publish("select::doc", { owner: this.owner, filterType: ft });
  }
}


