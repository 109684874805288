import { autoinject, bindable, bindingMode, containerless } from 'aurelia-framework';
import { Direccion } from 'entities/direccion';

@containerless()
@autoinject
export class EyDireccionCompleta {
  @bindable direccion: Direccion;
  constructor() {

  }
}
