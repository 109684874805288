import { autoinject, bindable, containerless } from 'aurelia-framework';

@containerless
@autoinject
export class EyDialogFullscreen {
    @bindable headerTitle;
    @bindable acceptName = "Save changes";
    @bindable acceptEnabled = true;
    @bindable hasButton = true;

    constructor(protected element: Element) {
    }

    accept() {
        let selectEvent = new CustomEvent("accept", { bubbles: true });
        this.element.dispatchEvent(selectEvent);
    }

    cancel() {
        let selectEvent = new CustomEvent("cancel", { bubbles: true });
        this.element.dispatchEvent(selectEvent);
    }
}