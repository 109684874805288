import { autoinject, bindable, bindingMode, containerless, observable, computedFrom } from 'aurelia-framework';
import { Documento, TipoDocumento } from 'entities/documento';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { Api } from 'utils/api';
import AuthService from 'authService';
import { DialogController } from 'aurelia-dialog';
import moment from 'moment';
import * as _ from 'lodash';
// import * as $ from 'jquery';
// import 'tempusdominus-bootstrap-4';
// import 'tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min.css';

// let minDate = moment('1950-01-01');
// let maxDate = moment('2050-01-01');

@containerless()
@autoinject
export class EyDocumentoUpload {

  @observable documento: Documento;
  title: string;
  errors: string;

  @observable tipDoc: TipoDocumento;
  filterSub: Subscription;

  selectedFiles: File[] = [];

  constructor(private api: Api, private ea: EventAggregator,
    private auth: AuthService, private controller: DialogController) {
  }

  activate(model) {
    this.title = this.api.tr("doc_editar");
    this.documento = model.doc;

    this.api.getOne(TipoDocumento, 'tipo_documentos', this.documento.tipo)
      .then(tpDoc => {
        if (tpDoc) {
          this.tipDoc = tpDoc;
        }
      });

      this.api.updateAptosMedicos();
      this.api.updateAtributosCalidad();
      // this.api.updateAtributosFormacion();
  }

  attached() {

    // let startDate = moment();
    // if (this.documento.fechaFirma) {
    //   startDate = moment(this.documento.fechaFirma, 'DD-MM-YYYY');
    // }

    // console.log("ATTACHED UPLOAD", this.documento);
    // $('#datetimepicker1').datetimepicker({
    //   format: 'L',
    //   locale: 'es',
    //   defaultDate: startDate,
    //   minDate: minDate,
    //   maxDate: maxDate,
    // });


    // let fechaCaducidad;
    // if (this.documento.fechaCaducidad) {
    //   fechaCaducidad = moment(this.documento.fechaCaducidad, 'DD-MM-YYYY')
    // }
    // $('#datetimepicker2').datetimepicker({
    //   format: 'L',
    //   locale: 'es',
    //   defaultDate: fechaCaducidad,
    //   minDate: minDate,
    //   maxDate: maxDate,
    // });

    // console.log("Fecha caducidad", fechaCaducidad);
  }

  uploadDocumento() {
    this.save();
  }

  save() {

    this.errors = "";


    let fInicio = moment(this.documento.fechaFirma, "DD-MM-YYYY");
    if (!fInicio.isValid()) {
      this.errors = this.api.tr("fecha_firma_error");
      return;
    }

    let fCad = moment(this.documento.fechaCaducidad, "DD-MM-YYYY");
    if (fCad.isValid() && fCad < fInicio) {
      this.errors = this.api.tr("fechas_error");
      return;
    }


    // let fechaInicio = $('#fecha_inicio').val()
    // console.log("INICIO:", fechaInicio);

    // let dt = moment(fechaInicio, 'DD/MM/YYYY');
    // if (dt < minDate || dt > maxDate) {
    //   this.errors = "Fecha no válida";
    //   return;
    // }

    // let fechaCaducidad;
    // if (this.tipDoc.caducidad > 0) {
    //   if ($('#fecha_caducidad')) {
    //     fechaCaducidad = $('#fecha_caducidad').val()
    //     console.log("CADUCIDAD:", fechaCaducidad);

    //     if (fechaCaducidad) {
    //       dt = moment(fechaCaducidad, 'DD/MM/YYYY');
    //       if (dt < minDate || dt > maxDate) {
    //         this.errors = "Fecha no válida";
    //         return;
    //       }
    //     }
    //   }
    // }

    console.log("this.tipDoc", this.tipDoc, this.documento);

    if (this.tipDoc.atributo_apto_med_oblig && !this.documento.apto_medico_id) {
      this.errors = this.api.tr("error_detectado");
      return;
    }

    if (this.tipDoc.atributo_calidad_oblig && !this.documento.calidad_doc_id) {
      this.errors = this.api.tr("error_detectado");
      return;
    }


    let extensionesValidas = [];
    this.documento.extensiones.split(',').forEach((ext) => {
      extensionesValidas.push(ext.trim().toUpperCase());
    });

    let file;
    if (this.selectedFiles.length > 0) {
      file = this.selectedFiles[0];

      var fileExtension = "." + file.type.split("/").pop().toUpperCase();

      if (!_.includes(extensionesValidas, fileExtension)) {
        this.errors = this.api.tr("error_extension") + " (" + this.documento.extensiones + ")";
        return;
      }

      // console.log("fileExtension", fileExtension, "name", file.name);

      if (file.size > (this.tipDoc.max_mb * 1024 * 1024)) {
        this.errors = "Archivo demasiado grande. Soportado un máximo de " + this.tipDoc.max_mb + " MB";
        return;
      }
      // this.files[0].size / 1024 / 1024 + "MB"
    }

    if (!this.documento.subido && !file) {
      this.errors = this.api.tr("archivo_error");;
      return;
    }

    let formData = new FormData();

    if (!this.documento.notas) {
      this.documento.notas = "";
    }
    formData.append("notas", this.documento.notas);
    if (this.documento.adjunta_com){
      formData.append("adjunta_com", "1");
    }
    if (this.documento.apto_medico_id){
      formData.append("apto_medico_id", this.documento.apto_medico_id);
    }
    if (this.documento.calidad_doc_id){
      formData.append("calidad_doc_id", this.documento.calidad_doc_id);
    }
    formData.append("fecha_inicio", this.documento.fechaFirma);
    if (this.documento.fechaCaducidad) {
      formData.append("fecha_caducidad", this.documento.fechaCaducidad);
    }
    if (file) {
      formData.append("file", file);
    }

    this.api.uploadFile('documentos_upload', this.documento.id, formData)
      .then(response => {
        console.log("Response: ", response)
        this.controller.close(true, response);

      })
      .catch(error => {
        // this.api.showSnackbar("Error enviando archivo", error, "error");
      });
  }

  cancel() {
    this.controller.cancel(false);
  }
}
