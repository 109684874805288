import { autoinject, bindable, bindingMode, observable } from 'aurelia-framework';
import { Api } from 'utils/api';

// TODO: update modal size with $('#myModal').modal('handleUpdate')

@autoinject
export class EyDialog {
  @bindable headerTitle;
  @bindable acceptName = "Aceptar";
  @bindable acceptEnabled = true;
  @bindable cancelEnabled = true;
  @bindable({ defaultBindingMode: bindingMode.oneTime }) public showButtons: boolean;

  @observable styleString: string = "";

  constructor(public api: Api, private element: Element) {
    this.showButtons = true;
  }

  public attached() {
    this.showButtonsChanged(this.showButtons);
  }

  setDialogWidth(width: number) {
    this.styleString = "min-width: " + width + "px; max-width: " + width + "px;";
  }

  setDialogHeight(height: number) {
    this.styleString = "min-height: " + height + "px; max-height: " + height + "px;";
  }

  accept() {
    let selectEvent = new CustomEvent("accept", { bubbles: true });
    this.element.dispatchEvent(selectEvent);
  }

  cancel() {
    let selectEvent = new CustomEvent("cancel", { bubbles: true });
    this.element.dispatchEvent(selectEvent);
  }

  showButtonsChanged(newValue) {
    this.showButtons = this.api.getBoolean(newValue);
  }

  // acceptEnabledChanged() {
  //   console.log("acceptEnabledChanged", this.acceptEnabled);
  // }
}
