import { autoinject, bindable, bindingMode, containerless, computedFrom, observable } from 'aurelia-framework';
import { Documento } from 'entities/documento';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FilterType } from './ey-documentos';
import AuthService from 'authService';

@containerless()
@autoinject
export class EyDocumentoResumen {
  @bindable documentos: Documento[] = [];
  @bindable filterType: FilterType;

  @observable documentosActivos: Documento[] = [];
  documentosSubidos: Documento[] = [];
  documentosPorSubir: Documento[] = [];
  documentosCaducados: Documento[] = [];
  documentosValidos: Documento[] = [];
  documentosPendientes: Documento[] = [];
  @bindable bloqueado: boolean = false;
  subido: number = 0;
  porSubir: number = 0;
  porSubirPor: string = "";
  caducado: number = 0;
  caducadoPor: string = "";
  valido: number = 0;
  noValido: number = 0;
  validoPor: string = "";

  pendiente: number = 0;
  pendientePor: string = "";

  constructor(private ea: EventAggregator, private auth: AuthService) {

  }

  documentosChanged() {
    console.log("EyDocumentoResumen documentosChanged", this.documentos);

    this.subido = 0;
    this.porSubir = 0;
    this.caducado = 0;
    this.valido = 0;
    this.noValido = 0;
    this.pendiente = 0;
    this.documentosSubidos = [];
    this.documentosPorSubir = [];
    this.documentosCaducados = [];
    this.documentosValidos = [];
    this.documentosPendientes = [];

    if (this.documentos) {
      this.documentos.forEach(d => {

        if (d.subido) {
          this.subido++;
          this.documentosSubidos.push(d);
        } else {
          this.porSubir++;
          this.documentosPorSubir.push(d);
        }

        if (!d.vigente && d.subido) {
          this.caducado++;
          this.documentosCaducados.push(d);
        }

        if (d.valido == 1) {
          this.valido++;
          this.documentosValidos.push(d);
        } else if (d.valido == 3) {
          this.pendiente++;
          this.documentosPendientes.push(d);
        } else {
          this.noValido++;
        }

      });
    }

    this.porSubirPor = ((this.porSubir / this.documentos.length) * 100).toFixed(0);
    this.caducadoPor = ((this.caducado / this.documentos.length) * 100).toFixed(0);
    this.validoPor = ((this.valido / this.documentos.length) * 100).toFixed(0);
    this.pendientePor = ((this.pendiente / this.documentos.length) * 100).toFixed(0);

    this.updateActiveDocs();
  }

  doFilter(filter: string) {
    console.log("doFilter", filter);

    console.log("EyDocumentos: runFilter", filter);
    var newFilter = FilterType.NO_FILTER;
    if (filter == "por_subir") {
      newFilter = FilterType.POR_SUBIR;
    } else if (filter == "caducado") {
      newFilter = FilterType.CADUCADO;
    } else if (filter == "valido") {
      newFilter = FilterType.VALIDO;
    } else if (filter == "pendiente") {
      newFilter = FilterType.PENDIENTE;
    }

    this.filterType = newFilter;
    this.updateActiveDocs();
    this.ea.publish("doFilter", { filter: this.filterType });
  }

  removeFilter() {
    this.ea.publish("removeFilter");
  }

  // @computedFrom('filterType')
  updateActiveDocs() {
    let docs: Documento[] = [];
    switch (this.filterType) {
      case FilterType.NO_FILTER:
        docs = this.documentos;
        break;
      case FilterType.POR_SUBIR:
        docs = this.documentosPorSubir;
        break;
      case FilterType.CADUCADO:
        docs = this.documentosCaducados;
        break;
      case FilterType.VALIDO:
        docs = this.documentosValidos;
        break;
      case FilterType.PENDIENTE:
        docs = this.documentosPendientes;
        break;
    }

    this.documentosActivos = docs;
  }

  @computedFrom('filterType')
  get getDocIds(): string {

    this.updateActiveDocs();

    let ids = "";
    this.documentosActivos.forEach(d => {
      if (ids == "") {
        ids = "" + d.id;
      } else {
        ids = ids + "," + d.id;
      }
    });
    return ids;
  }
}
