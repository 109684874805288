import { autoinject, bindable, containerless } from 'aurelia-framework';
import { EstadoDoc } from 'entities/documento';

@containerless
@autoinject
export class EyEstadoDocValido {
  @bindable estado: EstadoDoc;

  constructor() {
  }
}


